export interface Event {
  id: number
  name: string
  type: string | null
  description: string
  begin: Date
  end: Date
  cost: string | null
  streamingUrl: string | null
  bookingUrl: string | null
  ticketingUrl: string | null
  image: {
    url: string
    actionUrl: string
  } | null
  guests: EventGuests
  address: EventAddress | string | null
  multiDates: boolean
  isPoi?: boolean
  poiId?: number
}

/*
 * Visto che secondo il Backend gli id non sono univoci :D
 * */
export function generateEventUniqueIdentifier(idEvento: number, startEventDate: Date) {
  return `${idEvento}#${startEventDate.getTime()}`
}

export interface EventAddress {
  city: {
    name: string
    actionUrl: string | null
  } | null
  street: {
    name: string
    actionUrl: string | null
  } | null
}

export type Events = Event[]

export interface EventGuest {
  id: number
  fullName: string
  employment: string
}

export type EventGuests = EventGuest[]
