import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { FairPlacesMapComponent } from '@features/fair/pages/fair-places-page/map/fair-places-map.component'

const routes: Routes = [{ path: '', component: FairPlacesMapComponent, pathMatch: 'full' }]

@NgModule({
  declarations: [FairPlacesMapComponent],
  imports: [CommonModule, RouterModule.forChild(routes)],
})
export class FairPlacesMapModule {}
