import { EventDto, EventGuestDto, EventImageDto } from '../web-api/models/event.models'
import { Event, EventAddress } from '../models/event.model'

export function fromEventDto(eventDto: EventDto): Event {
  let address: EventAddress | null = null
  if (eventDto.placeInfo.inFair) {
    address = {
      city: eventDto.placeInfo.fairInfo.name
        ? {
            name: eventDto.placeInfo.fairInfo.name,
            actionUrl: null,
          }
        : null,
      street: eventDto.placeInfo.fairInfo.zone
        ? {
            name: eventDto.placeInfo.fairInfo.zone.room,
            actionUrl: null,
          }
        : null,
    }
  } else {
    if (eventDto.placeInfo.poiInfo) {
      address = {
        city: eventDto.placeInfo.poiInfo.address.city
          ? {
              name: eventDto.placeInfo.poiInfo.address.city,
              actionUrl: null,
            }
          : null,
        street: eventDto.placeInfo.poiInfo.name
          ? {
              name: eventDto.placeInfo.poiInfo.name,
              actionUrl: null,
            }
          : null,
      }
    } else if (eventDto.placeInfo.address) {
      address = {
        city: eventDto.placeInfo.address.city
          ? {
              name: eventDto.placeInfo.address.city,
              actionUrl: null,
            }
          : null,
        street: eventDto.placeInfo.address.street
          ? {
              name: eventDto.placeInfo.address.streetNumber
                ? `${eventDto.placeInfo.address.street}, ${eventDto.placeInfo.address.streetNumber}`
                : eventDto.placeInfo.address.street,
              actionUrl: null,
            }
          : null,
      }
    }
  }

  return {
    id: eventDto.id,
    name: eventDto.name,
    type: eventDto.eventType.name,
    description: eventDto.abstractHtml ? eventDto.abstractHtml : eventDto.abstract,
    begin: new Date(Date.parse(eventDto.startDate)),
    end: new Date(Date.parse(eventDto.endDate)),
    cost: eventDto.costs,
    streamingUrl: eventDto.streamingLink ?? null,
    bookingUrl: eventDto.bookLink ?? null,
    ticketingUrl: eventDto.ticketsLink ?? null,
    image: fromEventImageDto(eventDto.image),
    guests: eventDto.guests.map(fromEventGuestDto),
    address,
    isPoi: eventDto.placeInfo.fairInfo ? false : eventDto.placeInfo.poiInfo ? true : false,
    multiDates: eventDto.multipleDate ?? false,
    poiId: eventDto.placeInfo.poiInfo ? eventDto.placeInfo.poiInfo.id : null,
  }
}

export function fromEventGuestDto(eventGuestDto: EventGuestDto): { id: number; fullName: string; employment: string } {
  return {
    id: eventGuestDto.id,
    fullName: eventGuestDto.name,
    employment: eventGuestDto.abstractHtml ? eventGuestDto.abstractHtml : eventGuestDto.abstract,
  }
}

export function fromEventImageDto(eventImageDto: EventImageDto): { url: string; actionUrl: string | null } | null {
  return eventImageDto && eventImageDto.url
    ? {
        url: eventImageDto.url,
        actionUrl: eventImageDto.link ?? null,
      }
    : null
}
