import { FairPlace, FairPlaceAddress, FairPlaceEvent } from '@features/fair/models/fair-place.models'
import { EventDto } from '@shared/web-api/models/event.models'
import { FairPlaceDto } from '@shared/web-api/models/place.models'

export function fromFairPlaceDto(dto: FairPlaceDto): FairPlace {
  let address: FairPlaceAddress
  if (dto.placeInfo.inFair && dto.placeInfo.address) {
    address = {
      city: dto.placeInfo.fairInfo.address.city,
      street: dto.placeInfo.fairInfo.address.street,
      number: dto.placeInfo.fairInfo.address.streetNumber ?? undefined,
    }
  } else {
    if (dto.placeInfo.poiInfo) {
      address = {
        city: dto.placeInfo.poiInfo.address.city,
        street: dto.placeInfo.poiInfo.address.street,
        number: dto.placeInfo.poiInfo.address.streetNumber ?? undefined,
      }
    } else if (dto.placeInfo.address) {
      address = {
        city: dto.placeInfo.address.city,
        street: dto.placeInfo.address.street,
        number: dto.placeInfo.address.streetNumber ?? undefined,
      }
    }
  }

  return {
    id: dto.id,
    address,
    name: dto.name,
    imageUrl: dto.image.url,
    distance: dto.distance,
  }
}

export function fromEventDto(dto: EventDto): FairPlaceEvent {
  return {
    id: dto.id,
    name: dto.name,
    city: !!dto.placeInfo.poiInfo ? dto.placeInfo.poiInfo.address?.city : dto.placeInfo.address?.city ?? undefined,
    begin: new Date(dto.startDate).getTime(),
    end: !!dto.endDate ? new Date(dto.endDate).getTime() : undefined,
    imageUrl: dto.image.url,
  }
}
